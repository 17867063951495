/* Navbar.css */

.navbar-container {
  position: relative;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  transition: right 0.3s ease;
  z-index: 30;
}

.mobile-menu.open {
  right: 0;
}
